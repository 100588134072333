// React Libreries
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Layout from "./components/Layout/Layout";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Router>
    <Layout>
      <Routes>
        <Route exact path="/" element={<Layout />} />
      </Routes>
    </Layout>
  </Router>
);
